import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { getLanguage } from '@/lang/index'
import { constantRoutes } from '@/router'
import qs from 'qs'

const filterParams = (params) => {
  if (!params) return {};
  let result = {};
  for (let key in params) {
    const val = params[key];
    if (val !== undefined && val !== '') {
      result[key] = val;
    }
  }
  return result;
}

// prod
// https://fms.myfeline.co/   前端域名
// https://fms-api.myfeline.co/ 后端接口域名

// gray
// https://fms.gray.myfeline.co/   前端域名
// https://fms-api.gray.myfeline.co/  后端接口域名

// create an axios instance
const service = axios.create({
  baseURL: `${process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_BASE_HOST}${process.env.VUE_APP_BASE_API}`, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (config.method === 'get') {
      // If method is get，and params is Array(arr[1,2])， transform arr=1 & arr=2
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    if (store.getters.token) {
      // let each request carry token feline-token
      // let each request carry language feline-lang
      config.headers['feline-token'] = getToken()
      config.headers['feline-path'] = location.hash.substring(1)
    }
    config.headers['feline-lang'] = getLanguage()
    config.params = filterParams(config.params);
    if(process.env.NODE_ENV === 'development') {
      config.headers['feline-proxy-target'] = process.env.VUE_APP_BASE_HOST
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    
    if (res.responseCode !== 200 || !res.success) {
      // 401 登录过期需要重新登录
      if (res.responseCode === 401) {
        // to re-login
        // MessageBox.confirm(
        //   'You have been logged out, you can cancel to stay on this page, or log in again',
        //   'Confirm logout',
        //   {
        //     confirmButtonText: 'Re-Login',
        //     cancelButtonText: 'Cancel',
        //     type: 'warning'
        //   }
        // ).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })
        Message({
          message: res.message,
          type: 'error',
          duration: 1000,
          onClose() {
            store.dispatch('user/resetToken').then(() => {
              location.reload()
            })
          }
        })
      } else if (res.responseCode === 403) {
        // 
        Message({
          message: res.message || 'No Access',
          type: 'error',
          duration: 2000
        })
        return Promise.reject(new Error(res.message || 'No Access'))
      } else {
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 2000
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
